import axios from "axios";
import config from "../config/config.json";
import Request from "../context/Request";

export const authenticateByUsernameAndPassword = async (body) => {
    try {
        const response = await axios.post(`${config.api.invokeUrl}/auth/authenticate`, body);
        return { data: response.data };
    } catch (err) {
        return { err };
    }
};
export const authenticateByParams = async (body) => {
    try {
        const response = await axios.post(
            `${config.api.invokeUrl}/auth/by-params`, body);
        return { data: response.data };
    } catch (err) {
        return { err };
    }
};


export const retrieveSchoolList = async () => {
    return Request("get", `${config.api.invokeUrl}/schools/`);
};

export const retrieveLastLogin = async () => {
    return Request("get", `${config.api.invokeUrl}/schools/last/login/`);
};


export const retrieveStudentList = async (schoolId) => {
    return Request("get", `${config.api.invokeUrl}/schools/${schoolId}/students`);
};


export const retrieveBranchStudentList = async (branchId) => {
    return Request("get", `${config.api.invokeUrl}/schools/branch/${branchId}/students`);
};

export const retrieveSchoolSummary = async (schoolId) => {
    return Request("get", `${config.api.invokeUrl}/schools/summary`);
};



export const changeSchoolIdAll = async (data) => {
    return Request("post", `${config.api.invokeUrl}/users/convert/schoolId`, data);
};




export const retrieveAllStudentList = async () => {
    try {
        const response = await axios.get(
            `${config.api.invokeUrl}/schools/students`
        );
        return { data: response.data };
    } catch (err) {
        console.log(err);
        return { err };
    }
};






























export const retrieveTeacherList = async (schoolId) => {
    return Request("get", `${config.api.invokeUrl}/schools/${schoolId}/teachers`);
};
export const retrieveStaffList = async (schoolId) => {
    return Request("get", `${config.api.invokeUrl}/schools/${schoolId}/managers`);
};
export const retrieveTreeList = async () => {
    return Request("get", `${config.api.invokeUrl}/games/tree/`);
};

export const retrieveSchoolPeriodList = async () => {
    return Request("get", `${config.api.invokeUrl}/schools/periods/`);
};

export const retrieveBranchListByInstitutionIdSeasonIdGradeId = async (
    schoolId,
    gradeId
) => {

    return Request("get", `${config.api.invokeUrl}/schools/${schoolId}/${gradeId}/branches`);
};


export const retrieveStudentListByInstitution = async (
    institutionId,
    seasonId,
    gradeId
) => {
    return Request("get", `${config.api.invokeUrl}/institutions/${institutionId}/${seasonId}/${gradeId}/branches`);
};



export const retrieveHomeworkListByBranchId = async (branchId) => {
    return Request("get", `${config.api.invokeUrl}/homeworks/${branchId}`);
};

export const retrieveHomeworkListByStudentId = async (branchId, userId) => {
    return Request("get", `${config.api.invokeUrl}/homeworks/${branchId}/${userId}`);
};


export const createBranch = async (body) => {
    return Request("post", `${config.api.invokeUrl}/schools/branch`, body);
};
export const updateBranch = async (body) => {
    return Request("put", `${config.api.invokeUrl}/schools/branch`, body);
};




export const getUserStatisticsCount = async () => {
    return Request("get", `${config.api.invokeUrl}/users/statistics/count`);
};

export const getSchoolUserStatisticsCount = async (schoolId) => {
    return Request("get", `${config.api.invokeUrl}/users/statistics/count/${schoolId}`);
};













export const retrieveStudentData = async (studentId) => {
    return Request("get", `${config.api.invokeUrl}/reports/short/student/${studentId}`);
};






export const createInstitution = async (body) => {
    return Request("post", `${config.api.invokeUrl}/schools/school`,body);
};

export const updateInstitution = async (body) => {
    return Request("put", `${config.api.invokeUrl}/schools/school`,body);
};


export const createStudent = async (body, branchId, grade) => {
    return Request("post", `${config.api.invokeUrl}/schools/student/${branchId}`,body);
};

export const createIndividualStudent = async (body, grade) => {
    return Request("post", `${config.api.invokeUrl}/individual/student`,body);
};



export const updateStudent = async (body) => {
    return Request("put", `${config.api.invokeUrl}/schools/student`,body);
};


export const createTeacher = async (body, grade) => {
    return Request("post", `${config.api.invokeUrl}/schools/teacher`,body);
};

export const updateTeacher = async (body) => {
    return Request("put", `${config.api.invokeUrl}/schools/teacher`,body);
};



export const retrieveTeacherBranchList = async (teacherId) => {
    return Request("get", `${config.api.invokeUrl}/schools/teacher/${teacherId}/branch`);
};

export const addTeacherBranchList = async (body) => {
    return Request("post", `${config.api.invokeUrl}/schools/teacher/branch`, body);
};
export const removeTeacherBranchList = async (id) => {
    return Request("delete", `${config.api.invokeUrl}/schools/teacher/${id}/branch`);
};





export const createHomework = async (body, grade) => {
    return Request("post", `${config.api.invokeUrl}/homeworks/`,body);
};

export const updateHomework = async (body) => {
    return Request("put", `${config.api.invokeUrl}/homeworks/`,body);
};




export const createStaff = async (body, grade) => {
    return Request("post", `${config.api.invokeUrl}/schools/staff`,body);
};

export const updateStaff = async (body) => {
    return Request("put", `${config.api.invokeUrl}/schools/staff`,body);
};

export const retrieveAllGameData = async (type, id) => {
    return Request("get", `${config.api.invokeUrl}/reports/short/statics/${type}/${id}`);
};


export const retrieveEvolution = async (type, id) => {
    return Request("get", `${config.api.invokeUrl}/reports/short/evolution/${type}/${id}`);
};

export const retrieveEvolutionCount = async (type, id) => {
    return Request("get", `${config.api.invokeUrl}/reports/short/evolution/count/${type}/${id}`);
};


export const retrieveMostRecords = async (branchId) => {
    return Request("get", `${config.api.invokeUrl}/reports/branch/${branchId}/most`);
};










export const retrieveSkills = async () => {
    return Request("get", `${config.api.invokeUrl}/games/skill/`);
};
export const retrieveCommands = async () => {
    return Request("get", `${config.api.invokeUrl}/games/command/`);
};
export const createCommands = async (body) => {
    return Request("post", `${config.api.invokeUrl}/games/command`, body);
};
export const updateCommands = async (body) => {
    return Request("put", `${config.api.invokeUrl}/games/command`, body);
};
export const deleteCommands = async (commandId) => {
    return Request("delete", `${config.api.invokeUrl}/games/command/${commandId}`);
};




export const excelCheckData = async (studentData, institutionId) => {
    return Request("post", `${config.api.invokeUrl}/users/excel/check/${institutionId}`,studentData);
};
export const excelSaveData = async (studentData, institutionId) => {
    return Request("post", `${config.api.invokeUrl}/users/excel/save/${institutionId}`,studentData);
};




export const getTeacherBranchList = async (teacherId) => {
    return Request("get", `${config.api.invokeUrl}/schools/branch/teacher/${teacherId}`);
};






export const getAllUserList = async (studentData, institutionId) => {
    return Request("get", `${config.api.invokeUrl}/schools/all/user/list/`);
};
export const getAllSchoolList = async (studentData, institutionId) => {
    return Request("get", `${config.api.invokeUrl}/schools/all/school/list/`);
};
export const getAllBranchList = async (studentData, institutionId) => {
    return Request("get", `${config.api.invokeUrl}/schools/all/branch/list/`);
};
export const passwordSaveData = async (studentData) => {
    return Request("post", `${config.api.invokeUrl}/schools/all/password/`,studentData);
};








export const deleteBranch = async (id) => {
    return Request("delete", `${config.api.invokeUrl}/schools/delete/${id}/branch`);
};
export const deleteUser = async (id) => {
    return Request("delete", `${config.api.invokeUrl}/schools/delete/${id}/user`);
};
export const deleteSchool = async (id) => {
    return Request("delete", `${config.api.invokeUrl}/schools/delete/${id}/school`);
};