import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import ApproveButton from "../../../components/buttons/ApproveButton";
import language from "../../../context/language.json";
import {excelCheckData, excelSaveData, retrieveTeacherList} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {useAuth} from "../../../context/UserContext";

export default function ExportExcel (){

    const {
        institutionId,
        institution
    } = useAuth();

    const [uploadFile, setUploadFile] = useState(null);
    const [excelStatus, setExcelStatus] = useState({ data: null, state: null });
    const [studentData, setStudentData] = useState([]);

    useEffect(() => {
        const handleImportFromCSVOnChange = async () => {
            setExcelStatus({
                data: null,
                state: "WAITING",
            });
            const data = await uploadFile.arrayBuffer();
            const workbook = XLSX.read(data);
            const worksheet = workbook.Sheets["data"];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
            });
            setExcelStatus({
                data: jsonData,
                state: "READY",
            });
        };
        if (uploadFile) {
            handleImportFromCSVOnChange();
        }
    }, [uploadFile]);




    useEffect(() => {

        if (excelStatus.state === "READY") {
            const header = excelStatus.data[0];
            const dataArray = JSON.parse(JSON.stringify(excelStatus.data)).splice(1);
            if (
                dataArray.length > 0
            ) {
                const readStudents=[]
                for (const data of dataArray) {

                    readStudents.push({
                        username: data[0],
                        password:data[1],
                        name: data[2],
                        lastname:data[3],
                        mail: data[4],
                        gender:data[5],
                        phone:data[6],
                        branchName:data[7],
                        age:data[8],
                        studentNo:data[9],
                        controlStatus:null,
                        error:"",
                        institutionId: institutionId,
                        institutionName: institution.name,
                    } )

                }
                setStudentData(readStudents)
                console.log(readStudents)

            }
        }
    }, [excelStatus]);

    const handleOnChange = (type, value) => {
        if (type === "uploadFile") {
            setUploadFile(value[0]);
        }
    };

    useEffect(() => {

        if (studentData && Array.isArray(studentData) && studentData.length>0) {

        }
    }, [studentData]);



    const studentCheckData = async () => {
        console.log(studentData)
        const {data, err} = await excelCheckData(studentData, institutionId);
        if (err) {
            toast("Kontrol yapılıken hata oluştu");
            return;
        }
        setStudentData(data);
    };


    const studentSaveData = async () => {
        console.log(studentData)
        const {data, err} = await excelSaveData(studentData, institutionId);
        if (err) {
            toast("Kayıt yapılıken hata oluştu");
            return;
        }
        setStudentData(data);
    };



    const RenderTableData = () => {
        return (
            <>
                <div className="my-2">
                    <div className="flex flex-row justify-end">
                        <ApproveButton
                            title={language.control}
                            onClick={() => {
                                studentCheckData()
                            }}
                        />
                        <ApproveButton
                            title={language.save}
                            onClick={() => {
                                studentSaveData()
                            }}
                        />
                    </div>
                </div>
                <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs uppercase bg-left-panel text-text-color">
                    <tr>
                        <th scope="col" className="px-6 py-3"> control</th>
                        <th scope="col" className="px-6 py-3"> error</th>
                        <th scope="col" className="px-6 py-3"> schoolName</th>
                        <th scope="col" className="px-6 py-3"> name</th>
                        <th scope="col" className="px-6 py-3"> lastname</th>
                        <th scope="col" className="px-6 py-3"> branchName</th>
                        <th scope="col" className="px-6 py-3"> username</th>
                        <th scope="col" className="px-6 py-3"> password</th>
                        <th scope="col" className="px-6 py-3"> mail</th>
                        <th scope="col" className="px-6 py-3"> gender</th>
                        <th scope="col" className="px-6 py-3"> phone</th>
                        <th scope="col" className="px-6 py-3"> age</th>
                        <th scope="col" className="px-6 py-3"> studentNo</th>


                    </tr>
                    </thead>
                    <tbody>
                    {studentData.map((data, key) => (
                        <tr key={key} className="bg-white border-b">
                            <td className={`px-6 py-4`}>{data.controlStatus ? "OK" : "Error"}</td>
                            <td className={`px-6 py-4`}>{data.error}</td>
                            <td className={`px-6 py-4`}>{data.institutionName}</td>
                            <td className={`px-6 py-4`}>{data.name}</td>
                            <td className={`px-6 py-4`}>{data.lastname}</td>
                            <td className={`px-6 py-4`}>{data.branchName}</td>
                            <td className={`px-6 py-4`}>{data.username}</td>
                            <td className={`px-6 py-4`}>{data.password}</td>
                            <td className={`px-6 py-4`}>{data.mail}</td>
                            <td className={`px-6 py-4`}>{data.gender}</td>
                            <td className={`px-6 py-4`}>{data.phone}</td>
                            <td className={`px-6 py-4`}>{data.age}</td>
                            <td className={`px-6 py-4`}>{data.studentNo}</td>



                        </tr>
                    ))}
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <div>

            <input type="file"
                   onChange={(event) => {
                       handleOnChange("uploadFile", {
                           ...event.target.files,
                       });
                   }}
            />

            <RenderTableData/>

        </div>
    )
}