import { useEffect, useState } from "react";
import InputGroup from "../../inputs/InputGroup";
import PrimaryButton from "../../buttons/PrimaryButton";
import ApproveButton from "../../buttons/ApproveButton";
import { constructGradeOptionList } from "../../../utilization/InstitutionOperations";
import { toast } from "react-toastify";
import {
  createTeacher, deleteUser,
  updateTeacher,
} from "../../../requests/ApiOperitions";
import TeacherBranch from "./TeacherBranch";
import language from "../../../context/language.json";

const CreateOrUpdateInstitutionTeacher = (props) => {
  const { initialTeacher, institutionId, handleAfterCreateOrUpdate } = props;
  const [inputs, setInputs] = useState({
    name: "",
    lastname: "",
    role: "TEACHER",
    school: institutionId,
    username: "",
    password: "",
    grade:"",
    email:""
  });



  useEffect(() => {
    if (initialTeacher) {
      setInputs({
        id: initialTeacher.id,
        name: initialTeacher.name,
        lastname: initialTeacher.lastname,
        role: initialTeacher.role,
        school: institutionId,
        username: initialTeacher.username,
        email: initialTeacher.email,
        password: "",
      });
    }
  }, [initialTeacher]);

  const handleOnChange = (key, value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [key]: value,
    }));
  };

  const handleUpdateOnClick = async () => {
    const { data, err } = await updateTeacher({
      id:inputs.id,
      name: inputs.name,
      lastname: inputs.lastname,
      username: inputs.username,
      password: inputs.password,
      email: inputs.email,
    });
    if (err) {
      toast("Öğretmen güncellenirken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  const handleCreateOnClick = async () => {
    const body = {
      name: inputs.name,
      lastname: inputs.lastname,
      role: inputs.role,
      school: {id: institutionId},
      username: inputs.username,
      password: inputs.password,
      email: inputs.email,
    }
    const { data, err } = await createTeacher(body, inputs.grade);
    if (err) {
      toast("Öğretmen oluşturulurken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  const handleDeleteOnClick = async () => {
    const {data, err} = await deleteUser(inputs.id);
    if (err) {
      toast("Şube silinirken bir hata oluştu");
      return;
    }
    handleAfterCreateOrUpdate();
  };

  return (
    <div className="w-full">
      <div className="my-2">
        <div className="font-bold">
          {inputs.id ? "Öğretmen güncelle" : "Öğretmen oluştur"}
        </div>
      </div>
      <div className="h-[1px] w-full bg-gray-400"></div>
      <div className="my-1">
        <div className="flex flex-row flex-wrap">
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="text"
                  name="İsim"
                  value={inputs.name}
                  onChange={(e) =>
                      handleOnChange("name", e.target.value)
                  }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="text"
                  name="Soyadı"
                  value={inputs.lastname}
                  onChange={(e) =>
                      handleOnChange("lastname", e.target.value)
                  }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="text"
                  name="Kullanıcı giriş adı"
                  value={inputs.username}
                  onChange={(e) =>
                      handleOnChange("username", e.target.value)
                  }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="text"
                  name="Kullanıcı e-posta"
                  value={inputs.email}
                  onChange={(e) =>
                      handleOnChange("email", e.target.value)
                  }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="text"
                  name="Kullanıcı şifre"
                  value={inputs.password}
                  onChange={(e) =>
                      handleOnChange("password", e.target.value)
                  }
              />
            </div>
          </div>
          <div className="w-1/2">
            <div className="mx-2">
              <InputGroup
                  type="select"
                  name="Kullanıcı sınıf seviyesi"
                  optionList={constructGradeOptionList()}
                  value={inputs.grade}
                  onChange={(e) =>
                      handleOnChange("grade", e.target.value)
                  }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-1">
        <div className="flex flex-row justify-end">
          {inputs.id ? (
              <PrimaryButton title={language.update} onClick={handleUpdateOnClick}/>
          ) : (
              <ApproveButton title={language.insert} onClick={handleCreateOnClick}/>
          )}
        </div>
        <div className="flex flex-row justify-start">
          {inputs.id ? (
              <>
                <PrimaryButton title={"SİL"} onClick={handleDeleteOnClick}/>
              </>) : null}
        </div>
      </div>
      <TeacherBranch id={initialTeacher?.id}/>
    </div>
  );
};

export default CreateOrUpdateInstitutionTeacher;
