import UserCountPart from "../../institution/page/reportParts/UserCountPart";
import MainHeader from "../../../components/headers/MainHeader";
import DonutPart from "../../institution/page/reportParts/DonutPart";
import UsageCountPart from "../../institution/page/reportParts/UsageCountPart";
import SpeedPart from "../../institution/page/reportParts/SpeedPart";
import EvolutionPart from "../../institution/page/reportParts/EvolutionPart";
import UsagePart from "../../institution/page/reportParts/UsagePart";
import React, {useContext, useEffect, useState} from "react";
import {useAuth, UserContext} from "../../../context/UserContext";
import {
    getUserStatisticsCount,
    retrieveAllGameData,
    retrieveEvolution,
    retrieveEvolutionCount
} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import {calculateAVGData} from "../../institution/page/calculate/CalculateData";
import SchoolCountPart from "../../institution/page/reportParts/SchoolCountPart";
import language from "../../../context/language.json";


export const DashboardScreen = (props) => {


    const [countData, setCountData] = useState(null);
    const [avgData, setAvgData] = useState(null);
    const [countDataAll, setCountDataAll] = useState(null);
    const [avgDataAll, setAvgDataAll] = useState(null);
    const {type, selectedInstitution, selectedBranch, selectedStudent, selectedAdmin} = props;
    const [evolution, setEvolution] = useState(null);
    const [evolutionCount, setEvolutionCount] = useState(null);
    const [usageCount, setUsageCount] = useState(null);
    const [userStatistics, setUserStatistics] = useState(null);
    const [data, setData] = useState(null);
    const retrieveGameData = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }
        const {data, err} = await retrieveAllGameData(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        const calculatedAVGData = calculateAVGData(data.scoreTotalList, "avg")
        setAvgData(calculatedAVGData)
        const calculatedCountData = calculateAVGData(data.scoreTotalList, "count")
        setCountData(calculatedCountData);

        const calculatedAVGDataAll = calculateAVGData(data.scoreTotalListAll, "avg")
        setAvgDataAll(calculatedAVGDataAll)
        const calculatedCountDataAll = calculateAVGData(data.scoreTotalListAll, "count")
        setCountDataAll(calculatedCountDataAll);


        setData(data.scoreTotalList)
        setUsageCount(data)
    };







    const retrieveGameEvolution = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }

        const {data, err} = await retrieveEvolution(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolution(data)
    };

    const retrieveGameEvolutionCount = async () => {
        let dataType = "all"
        let id = 0;
        if (selectedInstitution) {
            dataType = "school"
            id = selectedInstitution
        }
        if (selectedBranch) {
            dataType = "branch"
            id = selectedBranch
        }
        if (selectedStudent) {
            dataType = "student"
            id = selectedStudent
        }
        const {data, err} = await retrieveEvolutionCount(dataType, id);
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setEvolutionCount(data)
    };

    const retrieveUserStatisticsCount = async () => {
        const {data, err} = await getUserStatisticsCount();
        if (err) {
            toast(language.reportGetData);
            return;
        }
        setUserStatistics(data)
    };

    useEffect(() => {
        retrieveUserStatisticsCount()
        retrieveGameData();
        retrieveGameEvolution();
        retrieveGameEvolutionCount();
    }, []);
    return (
        <div className="w-full">


            <SchoolCountPart/>
            <div className="mt-12"/>
            <UserCountPart userStatistics={userStatistics}/>

            <MainHeader name={language.reportSkillsRatio}/>
            {
                countData && avgData ? (
                    <DonutPart countData={countData} avgData={avgData}  countDataAll={countDataAll} avgDataAll={avgDataAll}/>
                ) : null
            }
            <div className="mt-12"/>
            <MainHeader name={language.reportUsageRatio}/>
            <UsageCountPart data={usageCount}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportCognitive}/>
            <SpeedPart data={data}/>
            <div className="mt-12"/>
            <MainHeader name={language.reportIntelligence}/>
            <EvolutionPart evolution={evolution}/>
            <div className="mt-12"/>
            <MainHeader name="Kullanım gelişimi"/>
            <UsagePart evolution={evolutionCount}/>

        </div>
    );
}

export default DashboardScreen;
