import {useEffect, useState} from "react";
import InputGroup from "../../inputs/InputGroup";
import PrimaryButton from "../../buttons/PrimaryButton";
import ApproveButton from "../../buttons/ApproveButton";
import {createStudent, deleteBranch, deleteUser, updateStudent} from "../../../requests/ApiOperitions";
import {toast} from "react-toastify";
import language from "../../../context/language.json";

export default function CreateOrUpdateInstitutionStudent(props) {
    const {
        initialStudent,
        institutionId,
        gradeId,
        branchId,
        seasonStartDate,
        handleAfterCreateOrUpdate,
        individual
    } = props;
    const [inputs, setInputs] = useState({
        name: "",
        lastname: "",
        ageGroup: gradeId,
        gender: seasonStartDate,
        role: "USER",
        school: institutionId,
        institutionID: institutionId,
        phone: "",
        username: "",
        email: "",
        password: "",
        branchId: branchId,
        startAt: "",
        endAt: "",
    });


    useEffect(() => {
        if (initialStudent) {
            setInputs({
                id: initialStudent.id,
                name: initialStudent.name,
                lastname: initialStudent.lastname,
                ageGroup: initialStudent.ageGroup?.id,
                gender: initialStudent.gender,
                role: initialStudent.role,
                school: institutionId,
                phone: initialStudent.phone,
                username: initialStudent.username,
                email: initialStudent.email,
                password: "",
                startAt: Date.parse(initialStudent.startAt),
                endAt: Date.parse(initialStudent.endAt),
            });
        }
    }, [initialStudent]);

    const constructGenderOptionList = () => {
        return [
            {name: "erkek", value: "erkek"},
            {name: "kız", value: "kız"},
        ];
    };

    const handleOnChange = (key, value) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            [key]: value,
        }));
    };

    const handleUpdateOnClick = async () => {
        const {data, err} = await updateStudent({
            id: inputs.id,
            name: inputs.name,
            lastname: inputs.lastname,
            gender: inputs.gender,
            phone: inputs.phone,
            username: inputs.username,
            email: inputs.email,
            password: inputs.password,
            startAt: new Date(inputs.startAt),
            endAt: new Date(inputs.endAt),
        });
        if (err) {
            toast("Öğrenci güncellenirken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };

    const handleCreateOnClick = async () => {
        const body = {
            name: inputs.name,
            lastname: inputs.lastname,
            gender: inputs.gender,
            role: inputs.role,
            school: {id: institutionId},
            phone: inputs.phone,
            username: inputs.username,
            email: inputs.email,
            password: inputs.password,
            startAt: new Date(inputs.startAt),
            endAt: new Date(inputs.endAt),
        }
        const {data, err} = await createStudent(body, branchId, gradeId);
        if (err) {
            toast("Öğrenci oluşturulurken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };


    const handleDeleteOnClick = async () => {
        const {data, err} = await deleteUser(inputs.id);
        if (err) {
            toast("Şube silinirken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };


    return (
        <div className="w-full">
            <div className="my-2">
                <div className="font-bold">
                    {inputs.id ? "Öğrenci güncelle" : "Öğrenci oluştur"}
                </div>
            </div>
            <div className="h-[1px] w-full bg-gray-400"></div>
            <div className="my-1">
                <div className="flex flex-row flex-wrap">
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="İsim"
                                value={inputs.name}
                                onChange={(e) => handleOnChange("name", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Soyisim"
                                value={inputs.lastname}
                                onChange={(e) => handleOnChange("lastname", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Kullanıcı adı"
                                value={inputs.username}
                                onChange={(e) => handleOnChange("username", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Kullanıcı şifre"
                                value={inputs.password}
                                onChange={(e) => handleOnChange("password", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="E-mail"
                                value={inputs.email}
                                onChange={(e) => handleOnChange("email", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="Telefon numarası"
                                value={inputs.phone}
                                onChange={(e) => handleOnChange("phone", e.target.value)}
                            />
                        </div>
                    </div>
                    {
                        individual ?
                            <>
                                <div className="w-1/2">
                                    <div className="mx-2">
                                        <InputGroup
                                            type="date"
                                            name="Başlama Tarihi"
                                            value={inputs.startAt}
                                            onChange={(e) => handleOnChange("startAt", e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="w-1/2">
                                    <div className="mx-2">
                                        <InputGroup
                                            type="date"
                                            name="Bitiş Tarihi"
                                            value={inputs.endAt}
                                            onChange={(e) => handleOnChange("endAt", e.target.value)}
                                        />
                                    </div>
                                </div>

                            </>

                            : null
                    }

                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type={"select"}
                                name="Cinsiyet"
                                optionList={constructGenderOptionList()}
                                value={inputs.gender}
                                onChange={(e) => handleOnChange("gender", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1">
                <div className="flex flex-row justify-end">
                    {inputs.id ? (
                        <PrimaryButton title={language.update} onClick={handleUpdateOnClick}/>
                    ) : (
                        <ApproveButton title={language.insert} onClick={handleCreateOnClick}/>
                    )}
                </div>
                <div className="flex flex-row justify-start">
                    {inputs.id ? (
                        <>
                            <PrimaryButton title={"SİL"} onClick={handleDeleteOnClick}/>
                        </>) : null}
                </div>
            </div>
        </div>
    );
}
