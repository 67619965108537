import {useEffect, useState} from "react";
import InputGroup from "../inputs/InputGroup";
import PrimaryButton from "../buttons/PrimaryButton";
import ApproveButton from "../buttons/ApproveButton";
import {
    createInstitution, deleteSchool,
    updateInstitution,
} from "../../requests/ApiOperitions";
import {toast} from "react-toastify";
import language from "../../context/language.json";


const typeForAdd = [
    {
        id: "brand",
        name: "Marka Ekle"
    },
    {
        id: "school",
        name: "Okul Ekle"
    }
]

const CreateOrUpdateInstitution = (props) => {
    const [type, setType] = useState("");

    const [brandList, setBrandList] = useState([]);
    const [mainList, setMainList] = useState([]);



    const {
        initialInstitution,
        institutionList,
        handleAfterCreateOrUpdate,
    } = props;

    const [inputs, setInputs] = useState({
        name: "",
        id: null,
        baseSchoolId: null,
        brandId:null,
        schoolId:null,
    });

    useEffect(() => {
        if (initialInstitution) {
            setInputs({
                name: initialInstitution.name,
                id: initialInstitution.id,
                baseSchoolId: initialInstitution.baseSchool?.id,
            });
        }
        else{
            setInputs({
                name: "",
                id: null,
                baseSchoolId: null,
            });
        }

    }, [initialInstitution]);


    useEffect(() => {
            const _mainList = institutionList.filter(i=> i.type === "SCHOOL" && i.kind === "MAIN")
            setMainList(_mainList)
    }, []);

    const handleOnChange = (key, value) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            [key]: value,
        }));
        if(key === "brandId"){
            const _brandList = institutionList.filter(i=> Number(i.baseSchool?.id) === Number(value))
            setBrandList(_brandList)
        }
    };

    const handleUpdateOnClick = async () => {
        const {data, err} = await updateInstitution({
            name: inputs.name,
            id: inputs.id,
            baseSchool: {id: type === "brand" ? inputs.brandId : inputs.schoolId},
            beginAt: Date.parse(inputs.beginAt),
            endAt: Date.parse(inputs.endAt)
        });
        if (err) {
            toast("Okul güncellenirken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };

    const handleCreateOnClick = async () => {
        const {data, err} = await createInstitution({
            name: inputs.name,
            baseSchool: {id: type === "brand" ? inputs.brandId : inputs.schoolId},
            beginAt: Date.parse(inputs.beginAt),
            endAt: Date.parse(inputs.endAt)
        });
        if (err) {
            toast("Okul oluşturulurken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };



    const handleDeleteOnClick = async () => {
        const {data, err} = await deleteSchool(inputs.id);
        if (err) {
            toast("Okul silinirken bir hata oluştu");
            return;
        }
        handleAfterCreateOrUpdate();
    };

    return (
        <div className="w-full">
            <div className="my-2">
                <div className="font-bold">
                    {inputs.id ? "Kurum güncelle" : "Kurum oluştur"}
                </div>
            </div>
            <div className="h-[1px] w-full bg-gray-400"></div>
            <div className="my-1">
                <div className="flex flex-row flex-wrap">
                    <div className="w-1/3">
                        <div className="mx-2">
                            <InputGroup
                                type="select"
                                name="Eklenecek Okul Türü"
                                optionList={typeForAdd}
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            />
                        </div>
                    </div>
                    {
                        type === "brand" || type === "school" ?

                            <div className="w-1/3">
                                <div className="mx-2">
                                    <InputGroup
                                        type="select"
                                        name="Markalar"
                                        optionList={mainList}
                                        value={inputs.brandId}
                                        onChange={(e) => handleOnChange("brandId", e.target.value)}
                                    />
                                </div>
                            </div>
                            : null
                    }
                    {
                        type === "school" ?
                            <div className="w-1/3">
                                <div className="mx-2">
                                    <InputGroup
                                        type="select"
                                        name="Kampüsler"
                                        optionList={brandList}
                                        value={inputs.schoolId}
                                        onChange={(e) => handleOnChange("schoolId", e.target.value)}
                                    />
                                </div>
                            </div>
                            : null
                    }


                    <div className="w-full">
                        <div className="mx-2">
                            <InputGroup
                                type="text"
                                name="ADI"
                                value={inputs.name}
                                onChange={(e) => handleOnChange("name", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="date"
                                name="Başlama Tarihi"
                                value={inputs.beginAt}
                                onChange={(e) => handleOnChange("beginAt", e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="w-1/2">
                        <div className="mx-2">
                            <InputGroup
                                type="date"
                                name="Bitiş Tarihi"
                                value={inputs.endAt}
                                onChange={(e) => handleOnChange("endAt", e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="my-1">
                <div className="flex flex-row justify-end">
                    {inputs.id ? (
                        <>
                            <ApproveButton title={language.update} onClick={handleUpdateOnClick}/>

                        </>) : (
                        <ApproveButton title={language.insert} onClick={handleCreateOnClick}/>
                    )}
                </div>
                <div className="flex flex-row justify-start">
                    {inputs.id ? (
                        <>
                            <PrimaryButton title={"SİL"} onClick={handleDeleteOnClick}/>
                        </>) : null}
                </div>
            </div>
        </div>
    );
};

export default CreateOrUpdateInstitution;
